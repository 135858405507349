import React from 'react'
import { UserChildCss } from './UserChildCss';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function NftCollection({data}) {

    console.log("datadata000",data)
  return (
    <UserChildCss>
    <div className="overview">
      <div className="table_title">NFT Sold</div>

      <div className="overview_section">
        {data?.data && data?.data?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>NFT Details</th>
                <th>Status</th>
                <th>Created On</th>
                <th>Royalty</th>
              </tr>
            </thead>
            <tbody>
              {data.data && data?.data?.map((i,ix) => {
                return (
                  <tr key={ix}>
                    {/* <Link
                      href="/nftdetails/[nftdetails]"
                      as={`/nftdetails/${i?.nft.id}`}
                    > */}
                      <td data-label="NFT Details" className="nft_cell">
                    <Link to={`/nfts/nftdetails/${i?.id}`}>
                        {/* <div> */}
                          {/* <img src={`${IMAGE_END_POINT}${i.nft.media}`} />{' '} */}
                          <h4>{i.name}</h4>
                        {/* </div> */}
                    </Link>
                      </td>
                    <td data-label="Category" className="cate_cell">
                      <h4>{i?.status}</h4>
                    </td>
                    <td data-label="Date/Time">
                      <h4>{`${moment(i.createdAt).format(
                        'DD-MMM-YY',
                      )}`}</h4>
                    </td>
                    <td data-label="Price">
                      <h4>{i.royalty}%</h4>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2>No NFT Sold</h2>
        )}
      </div>
    </div>
  </UserChildCss>
  )
}
