export const userLogin = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    SAVE_EMAIL: 'SAVE_EMAIL',
    VERIFY_ADMIN: 'VERIFY_ADMIN',
    RESEND_OTP: 'RESEND_OTP',
    CHECK_USER: 'CHECK_USER',
    USER_NAME: 'USER_NAME',
    PRE_LOG_SETTING_LIST:"PRE_LOG_SETTING_LIST",
    GET_CHAINS_DETAILS: "GET_CHAINS_DETAILS",
    ACCESS_CODES : "ACCESS_CODES",
    NOTIFICATION_LIST: "NOTIFICATION_LIST"

  };

  export const admin = {
    ADMIN: 'ADMIN',
    LOGOUT: 'LOGOUT',
  };

export const userData = {

    GET_DASHBOARD: 'GET_DASHBOARD',
    GET_USER_LIST: 'GET_USER_LIST',
    GET_USER_LIST_PARAMS: 'GET_USER_LIST_PARAMS',
    GET_USER_ACTIVITIES: 'GET_USER_ACTIVITIES',
    GET_NFTS_LIST: 'GET_NFTS_LIST',
    GET_MARKETPLACE_ITEM_LIST: 'GET_MARKETPLACE_ITEM_LIST',
    GET_AUCTIONS_LIST: 'GET_AUCTIONS_LIST',
    GET_BIDS_LIST: 'GET_BIDS_LIST',
    GET_KYC_DETAILS: 'GET_KYC_DETAILS',
    GET_SETTING_LIST:'GET_SETTING_LIST',
    GET_NETWORKS:'GET_NETWORKS',

  };
