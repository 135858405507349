import React from 'react'
import Userlist from '../Components/User'


export default function User() {
  return (
    <div>
      <Userlist/>
    </div>
  )
}
