import React from 'react'
import KycDetails from '../Components/Kyc'

export default function Kyc() {
  return (
    <div>
      <KycDetails/>
    </div>
  )
}
