import React from 'react'
import BidsData from '../Components/Bids'

export default function Bids() {
  return (
    <div>
      <BidsData/>
    </div>
  )
}
