import React from 'react'
import MarketPlaceData from '../Components/Marketplace'

export default function Marketplace() {
  return (
    <div>
      <MarketPlaceData/>
    </div>
  )
}
