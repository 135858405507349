import React from 'react'
import NftsData from '../Components/Nft'

export default function Nfts() {
  return (
    <div>
      <NftsData/>
    </div>
  )
}
