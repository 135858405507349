import React from 'react'
import DashboardData from '../Components/Dashboard'

export default function Dashboard() {
  return (
    <div>
      <DashboardData/>
    </div>
  )
}
