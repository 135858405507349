import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import URLS from "../../utils/urls";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";
import axios from "axios";
import LoaderCSS from "../Loader";
import { notificationAction } from "../../redux/admin/action";

export default function NotifyPop({ show, handleClick, limit, apiData }) {
  // const notification = useSelector(
  //   (state) => state?.persistReducer?.notifications
  // );

  const navigate = useNavigate();
  const IMAGE_END_POINT = URLS.EXCHANGE.ENDPOINTS.IMAGE_END_POINT;
  const notify_types = ["DISPUTE", "KYC", "MESSAGE", "PAYMENT"];
  const notifyBoxRef = useRef(null);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const [activePage, setActivePage] = useState(1);
  const [newData, setNewData] = useState();
  const [loader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const notification = useSelector((state) => state?.persistReducer?.notifications);
  const dispatch = useDispatch();


  const seeNotifyApi = async (id) => {
    try {
      await axios.post(`${URLS.EXCHANGE.ADMIN.SEEN_NOTIFICATION}`,{id: id});
      if(id=="checkAll"){
        dispatch(notificationAction(0));
        cogoToast.success("All Notification Seen")
      }else{
        dispatch(notificationAction(notification-1));
      }
      handleClick(false);
      setActivePage(1)
    } catch (e) {
      cogoToast.error("Notification Seen Failed");
    }
  };

  const fetchMoreData = async (page) => {
    console.log("newActive", page);
    setActivePage(page);
    try {
      const res = await axios.get(
        `${URLS.EXCHANGE.ADMIN.GET_NOTIFICATION}?page=${page}&limit=${limit}`
      );
      if (res.status === 200) {
        console.log("newRes", [...newData, ...res.data.data.data]);
        // setNewData([...newData, ...res.data.data.data]);
        setNewData((prevData) => [...prevData, ...res.data.data.data]);
        setTotalPages(Math.ceil(res.data.data.totalCount / limit));
        setActivePage(page)
        setLoader(false);
      }
    } catch (e) {
      cogoToast.error("Error In Getting Notifications");
    }
  };

  const handleRestClick = (i) => {
    if (i.type === "KYC") {
      navigate(`/kyc`);
    }
    if (i.type === "DISPUTE" || i.type === "MESSAGE") {
      navigate(`/chatsupport/userchatdetails/${i?.dispute}`);
    }
    if (i.type === "PAYMENT") {
      navigate(`/user/userdetails/${i?.user._id}`);
    }
    if (i.type === "NFTSoldAuction" || i.type === "NFTSoldMarketplace") {
      navigate(`/nfts/nftdetails/${i?.nft._id}`);
    }
    if(!i?.checked){
      seeNotifyApi(i._id)
    }
    handleClick(false);
  };
  const handleScroll = () => {
    if (notifyBoxRef?.current) {
      console.log("checking", activePage < totalPages, activePage);
      const { scrollTop, clientHeight, scrollHeight } = notifyBoxRef.current;
      console.log("========console", scrollTop + clientHeight, scrollHeight, totalPages,activePage )
      if (
        scrollTop + clientHeight +20>= scrollHeight &&
        activePage < totalPages &&
        !loader
      ) {
        setLoader(true);
        fetchMoreData(activePage + 1);
      }
    }
  };

  useEffect(() => {
    if (notifyBoxRef.current) {
      notifyBoxRef.current.scrollTop = 0;
    }
  }, [show]);

  useEffect(() => {
    setNewData(apiData?.data);
    if (apiData?.totalCount) {
      setTotalPages(Math.ceil(apiData?.totalCount / 10));
    }
  }, [apiData]);

  useEffect(() => {
    if (notifyBoxRef.current) {
      notifyBoxRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (notifyBoxRef.current) {
        notifyBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [show, activePage, totalPages, newData, loader]);

  console.log("checking", totalPages);

  return (
    <Root>
      <div className={show ? "popup active" : "popup"}>
        <div className="background_layer"></div>
        <div className="child_section">
          <button
            className="cls_btn"
            onClick={() => {
              handleClick(false);
              setActivePage(1)
            }}
          >
            <AiOutlineCloseCircle />
          </button>
          <h2>Notifications</h2>
          <div className="notify_box" ref={notifyBoxRef}>
            <button className="clr_all" onClick={()=>{seeNotifyApi("checkAll")}}>Check All</button>
            {newData?.map((i) => {
              return (
                <>
                  {notify_types.includes(i?.type) ? (
                    <div
                      className={
                        !i.checked ? "notify_child checked" : "notify_child"
                      }
                      onClick={() => handleRestClick(i)}
                    >
                      <img
                        className="img"
                        src={
                          i.avatar
                            ? `${IMAGE_END_POINT}${i?.user?.avatar}`
                            : "https://react.semantic-ui.com/images/avatar/large/matthew.png"
                        }
                        alt="User"
                      />
                      <div className="notify_child_1">
                        <h4>{i.type}</h4>
                        <p>
                          {" "}
                          <span>{i.user.username}</span> {i.message}
                        </p>
                        <p>
                          {moment(i.createdAt).isSame(today, "d")
                            ? `Today at ${moment(i.createdAt).format("h:mm A")}`
                            : moment(i.createdAt).isSame(yesterday, "d")
                            ? `Yesterday at ${moment(i.createdAt).format(
                                "h:mm A"
                              )}`
                            : `${moment(i.createdAt).format(
                                "DD-MMM-YY [at] h:mm A"
                              )}`}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        !i.checked ? "notify_child checked" : "notify_child"
                      }
                      onClick={() => handleRestClick(i)}
                    >
                      <img
                        className="img"
                        src={`${IMAGE_END_POINT}${i?.nft?.media}`}
                        alt="NFT"
                      />

                      <div className="notify_child_1">
                        <h4>
                          {i.type == "NFTSoldMarketplace"
                            ? "NFT Sold In Marketplace"
                            : "NFT Sold In Auction"}
                        </h4>
                        <p>
                          {" "}
                          <span>{i?.nft?.name}</span> {i?.message}
                        </p>
                        <p>
                          {moment(i.createdAt).isSame(today, "d")
                            ? `Today at ${moment(i.createdAt).format("h:mm A")}`
                            : moment(i.createdAt).isSame(yesterday, "d")
                            ? `Yesterday at ${moment(i.createdAt).format(
                                "h:mm A"
                              )}`
                            : `${moment(i.createdAt).format(
                                "DD-MMM-YY [at] h:mm A"
                              )}`}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

            {loader ? <div className="loading"><LoaderCSS/></div> : ""}
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  color: whitesmoke;
  h4,
  p {
    margin: 0;
    padding: 0;
  }
  .popup {
    .background_layer {
      backdrop-filter: blur(3px);
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: 999;
      display: none;
    }
    .child_section {
      background: #070c27;
      min-width: 350px;
      width: 350px;
      top: 2.5%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 95%;
      position: fixed;
      right: -450px;
      transition: all 1s;
      z-index: 9999;
      padding: 10px;
      text-align: center;
      border-radius: 20px;
      box-shadow: 0px 0px 10px -2px #fff;
      overflow: hidden;

      .notify_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
        .clr_all{
          padding: 7px;
          margin: 7px;
          cursor: pointer;
        }
        .notify_child {
          display: flex;
          gap: 10px;
          margin: 5px;
          border-radius: 5px;
          padding: 5px;
          background-color: #25262d;
          cursor: pointer;
          &:hover {
            background-color: #4b4b4b;
          }
          .img {
            min-height: 60px;
            min-width: 60px;
            height: 60px;
            width: 60px;
            object-fit: cover;
          }
          .notify_child_1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            p {
              text-align: left;
            }
            span {
              font-weight: 700;
              text-transform: capitalize;
            }
          }
        }
        .checked {
          background-color: #2a3469;
      
        }
        .loading{
          padding: 10px;
        }
      }

      .cls_btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        height: 40px;
        width: 40px;
        border: transparent;
        svg {
          color: white;
          font-size: 30px;
        }
      }

      .btns2 {
        height: 30px;
        width: 50px;
        margin: 3px;
      }
    }
    &.active {
      .background_layer {
        display: unset;
      }
      .child_section {
        right: 15px;
      }
    }
  }
`;
