import React from 'react'
import AuctionsData from '../Components/Auction'

export default function Auction() {
  return (
    <div>
      <AuctionsData/>
    </div>
  )
}
